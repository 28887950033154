<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <bonus :bonus-balance="bonusBalance" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <swiper :swiper-data="swiperData" />
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        xl="8"
        md="6"
      >
        <div class="h3">
          Популярные товары
        </div>
      </b-col>
      <b-col
        xl="4"
        md="6"
        class="text-right text-primary"
      >
        <div class="font-small-3 mr-25 mb-0">
          <b-link :to="{ name: 'product-list' }">
            Показать все
          </b-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <product-swiper :products="products" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BRow, BCol, BLink } from 'bootstrap-vue'
import Swiper from '@/views/main/client/Swiper.vue'
import Bonus from '@/views/main/client/Bonus.vue'
import ProductSwiper from '@/views/main/client/ProductSwiper.vue'
import { action, bonus, mainCarousel } from './client'

export default {
  components: {
    BRow,
    BCol,

    Swiper,
    Bonus,
    ProductSwiper,
    BLink,
  },
  setup() {
    const isLoading = ref(true)
    const swiperData = ref([])
    const bonusBalance = ref(0)
    const products = ref([])
    const { activeElementListAll } = action()
    const { getBalance } = bonus()
    const { getProductCarousel } = mainCarousel()
    Promise.all([
      activeElementListAll(),
      getBalance(),
      getProductCarousel(),
    ]).then(([actionList, balance, productList]) => {
      swiperData.value = actionList || []
      bonusBalance.value = balance || 0
      products.value = productList || []
      isLoading.value = false
    })
    return {
      isLoading, swiperData, bonusBalance, products,
    }
  },
}
</script>
