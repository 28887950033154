import conect from './api'

const { axiosIns } = conect()

export default {
  async elementList(params = {}) {
    const { error, result } = (await axiosIns.get('/action/', { params })).data
    if (error) throw error

    return result
  },
  async getElement(id) {
    const { error, result } = (await axiosIns.get(`/action/${id}`)).data
    if (error) throw error

    return result
  },
  async updateElement(id, ...args) {
    const { error, result } = (await axiosIns.put(`/action/${id}`, ...args)).data
    if (error) throw error

    return result
  },
  async productList(params = {}) {
    const { error, result } = (await axiosIns.get('/action-product/', { params })).data
    if (error) throw error

    return result
  },
  async getElementListAll(params = {}) {
    const { error, result } = (await axiosIns.get('/action/all/', { params })).data
    if (error) throw error

    return result
  },
}
