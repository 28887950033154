import conect from './api'

const { axiosIns } = conect()

export default {
  async elementList(params = {}) {
    const { error, result } = (await axiosIns.get('/product/', { params })).data
    if (error) throw error

    return result
  },
  async getElement(id) {
    const { error, result } = (await axiosIns.get(`/product/${id}`)).data
    if (error) throw error

    return result
  },
  async elementListAll(params = {}) {
    const { error, result } = (await axiosIns.get('/product/all/', { params })).data
    if (error) throw error

    return result
  },
  async statisticListAll(params = {}) {
    const { error, result } = (await axiosIns.get('/product/statistic/', { params })).data
    if (error) throw error

    return result
  },
}
