<template>
  <div class="bonus-balanse">
    <b-card
      class="card-bonus-balanse"
    >
      <b-row>
        <b-col class="text-center">
          <b-img
            :src="require('@/assets/images/logo/logo.png')"
          />
        </b-col>

      </b-row>
      <b-row class="pt-1">
        <b-col>
          <div class="h3">
            Мой баланс
          </div>
          <div class="text-muted font-small-3">
            на {{ formatDate(new Date) }}
          </div>
        </b-col>
        <b-col class="text-right">
          <div class="text-balance">
            {{ numFormatter(bonusBalance) }}
          </div>
          <div class="text-bonus">
            бонусов
          </div>
          <div
            v-if="expectation"
            class="text-muted font-small-1 mt-1"
          >
            на рассмотрении 0
          </div>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { numFormatter, formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    bonusBalance: {
      type: Number,
      default: 0,
    },
    expectation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    numFormatter,
    formatDate,
  },
}
</script>

<style lang="scss" scoped>
  .bonus-balanse {
    @media(max-width:575.98px) {
      padding: 0 0;
    }
    .card-bonus-balanse {
      height: 150px;
      border-radius: 15px;
      img {
        height: 25px;
      }

      .text-balance {
        color: #ea5455;
        font-weight: 600;
        font-size: 30px;
        line-height: 39px;
      }
      .text-bonus {
        color: #ea5455;
        font-weight: 300;
        font-size: 14px;
        line-height: 10px;
      }
    }
  }

</style>
