<template>
  <swiper
    class="swiper-centered-slides"
    :options="swiperOptions"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
  >

    <!-- slides -->
    <swiper-slide
      v-for="item in swiperData"
      :key="item.id"
      class="rounded swiper-shadow"
    >
      <div
        class="swiper-item"
        :style="{ 'background-color': `#${item.color.color}`}"
        @click="$router.push({ name: 'action-view', params: { id: item.id } })"
      >
        <div class="swiper-text pt-md-1 pt-sm-50">
          {{ item.title }}
        </div>
        <div class="swiper-icon">
          <feather-icon
            icon="GithubIcon"
            size="28"
            :style="{ 'color': `#${item.color.color}`}"
          />
        </div>
      </div>

    </swiper-slide>

    <!-- Add Arrows -->
    <div
      slot="button-next"
      class="swiper-button-next"
    />
    <div
      slot="button-prev"
      class="swiper-button-prev"
    />
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    swiperData: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      swiperData2: [
        {
          id: 1, icon: 'GithubIcon', text: '7% бонусами', color: '#EDF8EF',
        },
        {
          id: 2, icon: 'FacebookIcon', text: 'Бонусы в подарок', color: '#B2EBF2',
        },
        {
          id: 3, icon: 'TwitterIcon', text: 'Привелегии', color: '#FFEFD8',
        },
        {
          id: 4, icon: 'InstagramIcon', text: 'Привелегии', color: '#F0E7FB',
        },
      ],
      swiperOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/swiper.scss';
.swiper-item {
  height: 100%;
  padding: 1rem;
  border-radius: 12px !important;
}

.swiper-centered-slides {
  width: 100%;
  padding: 1rem 80px;
}
.swiper-centered-slides.swiper-container .swiper-slide.swiper-slide-active {
  border: none;
}
.swiper-centered-slides.swiper-container .swiper-slide {
  height: 150px;
  width: 200px !important;
  padding:0;
  border-radius: 12px!important;
  @media(max-width:575.98px) {
    width: 150px !important;
  }
}
.swiper-icon {
  position: absolute;
  padding: 10px;
  background-color: white;
  border-radius: 15px;
  bottom: 15px;
}
</style>
