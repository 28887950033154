<template>
  <swiper
    class="swiper-centered-slides"
    :options="swiperOptions"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
  >

    <!-- slides -->
    <swiper-slide
      v-for="item in products"
      :key="item.id"
      class="card card-product swiper-shadow"
    >

      <div class="item-img text-center">
        <b-link :to="{ name: 'product-view', params: { id: item.id } }">
          <b-img
            class="product-img"
            rounded
            fluid
            :src="item.file && item.file.path ? item.file.path : '/no-image.png'"
          />
        </b-link>
        <div
          v-if="item.actionPrice && item.actionPrice.quantity"
          class="price-quantity"
        >
          <div class="d-flex align-items-left justify-content-end">
            <b-img :src="boxQuantity" />
            <div class="text-primary">
              +{{ item.actionPrice.quantity.stepProfit }}
            </div>
            <div> - за {{ item.actionPrice.quantity.step }} шт.</div>
          </div>
        </div>
      </div>
      <h5 class="item-name">
        <b-link
          class="text-body"
          :to="{ name: 'product-view', params: { id: item.id } }"
        >
          {{ item.name }}
        </b-link>
      </h5>
      <div class="item-property  text-muted font-small-3">
        <div class="item-property-label">
          Производитель
        </div>
        <div class="item-property-text">
          {{ item.brand }}
        </div>
        <div class="item-property-label">
          Артикул
        </div>
        <div class="item-property-text">
          {{ item.articul }}
        </div>
      </div>
      <div class="item-description">
        {{ item.description }}
      </div>
      <div class="d-flex align-items-left justify-content-end">
        <div
          v-if="item.actionPrice.bonus && item.actionPrice.bonus"
          class="price-old text-muted small"
        >
          <s>{{ item.actionPrice && item.actionPrice.standard && item.actionPrice.standard.profit ? item.actionPrice.standard.profit : '5' }}B</s>
        </div>
        <div class="price-main font-weight-bold">
          {{ item.actionPrice.bonus && item.actionPrice.bonus ? item.actionPrice.bonus.profit : item.actionPrice.standard && item.actionPrice.standard.profit ? item.actionPrice.standard.profit : '5' }}B
        </div>
        <b-img
          v-if="item.actionPrice.bonus && item.actionPrice.bonus"
          :src="arrowPrice"
        />
        <b-img :src="homeBonus" />
      </div>

    </swiper-slide>

    <!-- Add Arrows -->
    <div
      slot="button-next"
      class="swiper-button-next"
    />
    <div
      slot="button-prev"
      class="swiper-button-prev"
    />
  </swiper>
</template>

<script>
/* eslint-disable global-require */

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg, BLink } from 'bootstrap-vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BLink,
  },
  props: {
    products: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      arrowPrice: require('@/assets/images/price-group/arrow_price.svg'),
      boxQuantity: require('@/assets/images/price-group/box_quantity.svg'),
      homeBonus: require('@/assets/images/price-group/home_bonus.svg'),
      swiperOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/swiper.scss';

.swiper-centered-slides {
  width: 100%;
  padding: 1rem 80px;
}
.swiper-centered-slides.swiper-container .swiper-slide.swiper-slide-active {
  border: none;
}

.swiper-centered-slides.swiper-container .card-product {
  width: 280px !important;
  padding: 1.5rem;
  border-radius: 12px!important;
}

.item-property {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: 500;
  text-align: left;
  .item-property-label {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 0.6rem;
  }
  .item-property-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 0.6rem;
  }
}

.item-action {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: 700;
}
.price-main{
  font-size: 24px;
}
.price-old{
  margin-top: 10px;
  margin-right: 10px;
}
.price-quantity {
  position: absolute;
  top: 1.0rem;
  right: 1.5rem;
  font-size: 16px;
  font-weight: 500;
}
.item-img{
  padding-top: 17px;
}
</style>
