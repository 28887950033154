import actionRepository from '@/repository/actionRepository'
import bonusRepository from '@/repository/bonusRepository'
import productRepository from '@/repository/productRepository'
import store from '@/store'

export const action = () => {
  const activeElementListAll = () => new Promise(resolve => {
    actionRepository.getElementListAll({ actionActive: 1 })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
        resolve([])
      })
  })
  return { activeElementListAll }
}

export const bonus = () => {
  const getBalance = () => new Promise(resolve => {
    bonusRepository.bonusBalance()
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
        resolve(0)
      })
  })
  return { getBalance }
}

export const mainCarousel = () => {
  const getProductCarousel = () => new Promise(resolve => {
    productRepository.elementListAll({ mainCarousel: 1, actionActive: 1 })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
        resolve(0)
      })
  })
  return { getProductCarousel }
}
